/* ==========================================================================
   Mixins
   ========================================================================== */

$smallmobile : 766px;
$mobile : 950px;
$tablet : 950px;
$desktop : 1200px;

@mixin breakpoint($point)
{
	@if $point == "smallmobile" {
		@media (max-width: $smallmobile) {@content;}
	}

	@if $point == "mobile" {
		@media (max-width: $mobile) {@content;}
	}

	@if $point == "header" {
		@media (max-width: $header) {@content;}
	}

	@if $point == "tablet" {
		@media (max-width: $tablet) {@content;}
	}

	@if $point == "desktop" {
		@media (min-width: $tablet) {@content;}
	}

	@if $point == "tablet-up" {
		@media (min-width: $mobile) {@content;}
	}
}


@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content-ratio {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin rowMachine($numPerRow, $margin) {
  width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
  &:nth-child(n) {
    margin-bottom: $margin;
    margin-right: $margin;
  }
  &:nth-child(#{$numPerRow}n) {
    margin-right: 0;
    margin-bottom: 0;
  }
}



/* ====================================================================================================================
   Mixin : Linear-gradient
   Usage :
		@include linear-gradient(#31B7D7, #EDAC7D);
  		@include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
  		@include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
   ==================================================================================================================== */

@mixin linear-gradient($direction, $color-stops...)
{
	// Direction has been omitted and happens to be a color-stop
	@if is-direction($direction) == false {
		$color-stops: $direction, $color-stops;
		$direction: 180deg;
	}

	background: nth(nth($color-stops, 1), 1);
	background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
	background: linear-gradient($direction, $color-stops);
}

/// Test if '$value' is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value)
{
	$is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
	$is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
	@return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value)
{
	@if is-direction($value) == false {
    	@error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
	}
	
	$conversion-map: (
	    to top          : bottom,
	    to top right    : bottom left,
	    to right top    : left bottom,
	    to right        : left,
	    to bottom right : top left,
	    to right bottom : left top,
	    to bottom       : top,
	    to bottom left  : top right,
	    to left bottom  : right top,
	    to left         : right,
	    to left top     : right bottom,
	    to top left     : bottom right
	);

	@if map-has-key($conversion-map, $value) {
    	@return map-get($conversion-map, $value);
  	}

  	@return 90deg - $value;
}